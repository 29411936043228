/* You can add global styles to this file, and also import other style files */
@import "~node_modules/bootstrap/scss/bootstrap";
@import "~font-awesome/css/font-awesome.css";
@import "~@fortawesome/fontawesome-free/css/all.css";

html {
  min-height: 100%;
}

body {
  max-height: 100vh;
}

router-outlet {
  height: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.navbar-dark {
  .cursor-pointer {
    color: white;
  }
}

.relative {
  position: relative;
}

.table-sm-sm th,
.table-sm-sm td {
  @media (min-width: 576px) {
    padding: 0.3rem;
  }
}

.w-50-sm {
  max-width: 66%;
  @media (min-width: 576px) {
    width: 50%;
  }
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 102px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-gradient(linear, left bottom, left top, from(#adadad), to(#c0bebe));
  background-image: -webkit-linear-gradient(bottom, #adadad 0%, #c0bebe 100%);
  background-image: linear-gradient(to top, #adadad 0%, #c0bebe 100%);
}

.margin-top {
  margin-top: 90px;
}

.padding-top {
  padding-top: 90px;
}

.form-control:focus {
 box-shadow: none;
}


@media only screen and (max-width: 575px) {

  .input-group {
    left: 5px !important;
    bottom: 5px !important;
    width: 75% !important;
    height: 3% !important;
  }

  .advanced_search {
    width:100% !important;
  }

  .ul-dropdown {
    left: 5px !important;
    width: 75% !important;
  }

  .underline {
    left: 17px !important;
  }

}

@media only screen and (max-height: 875px) {

  .table-scroll tbody {
    max-height: 400px !important;
  }

}
